import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [BatchName, setBatchName] = React.useState(FetchData.meta.batch_name_order)
  const [BatchId, setBatchID] = React.useState(FetchData.meta.batch_id_order)
  const [AmountToPay, setAmountToPay] = React.useState(FetchData.meta.amount_to_pay)
  const [BatchModal, setBatchModal] = React.useState(false)
  const [StudentID, setStudentID] = React.useState(FetchData.meta.user_id_order)
  const [StudentEmail, setStudentEmail] = React.useState(FetchData.meta.user_email_order)
  const [StudentPhoneNumber, setStudentPhoneNumber] = React.useState(FetchData.meta.user_phone_order)
  const [StudentUserName, setStudentUserName] = React.useState(FetchData.meta.user_username_order)
  const [StudentModal, setStudentModal] = React.useState(false)
  const [PaidAmount, setPaidAmount] = React.useState(FetchData.meta.paid_amount)
  const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
  const [VerificationMethod, setVerificationMethod] = React.useState(FetchData.meta.verification_method)
  const [VerificationDate, setVerificationDate] = React.useState(FetchData.meta.verification_date)
  const [VerificationID, setVerificationID] = React.useState(FetchData.meta.verification_id)
  const [BatchCount, setBatchCount] = React.useState(FetchData.meta.batch_count_order)

  const [InstituteID, setInstituteID] = React.useState(FetchData.meta.batch_institute_id)
  const [InstituteName, setInstituteName] = React.useState(FetchData.meta.batch_institute_name)
  const [InstituteModal, setInstituteModal] = React.useState(false)
  const [InstituteList, setInstituteList] = React.useState([])

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  useEffect(() => {

    InstituteListFetch()
  }, [])

  const InstituteListFetch = () => {
    AdsGETApiAuth(
      {
        per_page: 100,
        _fields: "id,title,slug,date,meta"
      },
      slugs.db_slug_institute
    )
      .then((res) => {
        setInstituteList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": `${StudentID}-${InstituteID}`,
      "status": "publish",
      "meta": {
        "user_id_order": `${StudentID}`,
        "user_email_order": StudentEmail,
        "user_phone_order": `${StudentPhoneNumber}`,
        "user_username_order": StudentUserName,
        "paid_amount": `${PaidAmount}`,
        "amount_to_pay": `${AmountToPay}`,
        "verification_status": VerificationStatus,
        "verification_method": VerificationMethod,
        "verification_date": `${VerificationDate}`,
        "verification_id": `${VerificationID}`,
        "exam_count_order": `${BatchCount}`,
        "batch_institute_name": `${InstituteName}`,
        "batch_institute_id": `${InstituteID}`
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_institute_exam_orders)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Order Added Successfully")
          history.push(`${slugs.institute_exam_order_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_institute_exam_orders, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`Order edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Order Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">User ID</td>
                <td className="col-6" style={{ cursor: "pointer" }}
                  onClick={() => { setInstituteModal(!InstituteModal) }}>
                  <div className="input-common">
                    {StudentID}&nbsp;
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">User Name</td>
                <td className="col-6" style={{ cursor: "pointer" }}
                  onClick={() => { setInstituteModal(!InstituteModal) }}>
                  <div className="input-common">
                    {StudentUserName}&nbsp;
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">User Phone Number</td>
                <td className="col-6" style={{ cursor: "pointer" }}
                  onClick={() => { setInstituteModal(!InstituteModal) }}>
                  <div className="input-common">
                    {StudentPhoneNumber}&nbsp;
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">User Email</td>
                <td className="col-6" style={{ cursor: "pointer" }}
                  onClick={() => { setInstituteModal(!InstituteModal) }}>
                  <div className="input-common">
                    {StudentEmail}&nbsp;
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Paid Amount</td>
                <td className="col-6">
                  <input className="input-common"
                    value={PaidAmount} placeholder="Paid Amount"
                    onChange={(event) => { setPaidAmount(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Count</td>
                <td className="col-6">
                  <input className="input-common"
                    value={BatchCount} placeholder="Batch Count"
                    onChange={(event) => { setBatchCount(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Amount to Pay</td>
                <td className="col-6">
                  <input className="input-common"
                    value={AmountToPay} placeholder="Amount To Pay"
                    onChange={(event) => { setAmountToPay(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Verification Status</td>
                <td className="col-6">
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"Unverified"} />
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"Verified"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Verification Method</td>
                <td className="col-6">
                  <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Esewa"} />
                  <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Khalti"} />
                  <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Manual"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Verification Date</td>
                <td className="col-6">
                  <input className="input-common"
                    value={VerificationDate} placeholder="Verification Date"
                    onChange={(event) => { setVerificationDate(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Verification ID</td>
                <td className="col-6">
                  {VerificationID.includes("https:") ?
                    <a href={VerificationID} target="_blank">
                      <img src={VerificationID} style={{ height: "200px" }} />
                    </a>
                    :
                    <input className="input-common"
                      value={VerificationID} placeholder="Verification ID"
                      onChange={(event) => { setVerificationID(event.target.value) }}
                    />
                  }
                </td>
              </tr>
              <tr>
                <td className="col-3">Institute Id</td>
                <td className="col-6"
                  onClick={() => {
                    setInstituteModal(!InstituteModal)
                  }}
                >
                  <div className="input-common">
                    {InstituteID}&nbsp;
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Institute Name</td>
                <td className="col-6"
                  onClick={() => {
                    setInstituteModal(!InstituteModal)
                  }}
                >
                  <div className="input-common">
                    {InstituteName}&nbsp;
                  </div>
                  {InstituteModal &&
                    <section className="modal">
                      <div className="row">
                        <div className="col-md-3 col-1"></div>
                        <div className="col-md-6 col-10  shadow bg-white">
                          <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                            <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                              onClick={() => {
                                setInstituteID('')
                                setInstituteName("")
                                setInstituteModal(!InstituteModal)
                              }}
                            >X</div>

                            <div onClick={() => {
                              setInstituteID('')
                              setInstituteName("")
                              setInstituteModal(!InstituteModal)
                            }}>
                              Clear
                            </div>
                            <table>
                              {InstituteList.map((item, index) => (
                                <tr key={index}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setStudentID(`${item.meta.user_id}`)
                                    setStudentUserName(`${item.meta.user_name}`)
                                    setStudentPhoneNumber(`${item.meta.user_phone_number}`)
                                    setStudentEmail(`${item.meta.user_email}`)
                                    setInstituteID(`${item.id}`)
                                    setInstituteName(item.meta.institute_name)
                                    setInstituteModal(!InstituteModal)
                                  }}
                                >
                                  <td className="col-1">
                                    {InstituteID !== item.id ?
                                      <i className="fa fa-square-o" />
                                      :
                                      <i className="fa fa-check-square-o" />
                                    }
                                  </td>
                                  <td className="col-2">
                                    {item.id}
                                  </td>
                                  <td className="col-3">
                                    {item.meta.institute_name}
                                  </td>
                                  <td className="col-3">
                                    {item.meta.institute_address}
                                  </td>
                                  <td className="col-3">
                                    {item.meta.institute_email}
                                  </td>

                                </tr>
                              ))}
                            </table>
                          </div>
                        </div>
                      </div>
                    </section>
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Order
              </div>
            </div>
          </div>
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div>
  )

}