import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    compact:true,
    width: "100px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
    compact:true,
  },
  {
    id: 32,
    name: "Exam Time",
    selector: (row) => row.meta.exam_time,
    sortable: true,
    reorder: true,
    center:true,
  },
  {
    id: 33,
    name: "Availability",
    selector: (row) => row.meta.available_unavailable,
    sortable: true,
    reorder: true,
    width: "150px",
    compact:true,
    center:true,
  },
  {
    id: 33,
    name: "Exam Price",
    selector: (row) => row.meta.exam_price,
    sortable: true,
    reorder: true,
    width: "100px",
    compact:true,
    center:true,
  },
  {
    id: 33,
    name: "Reset Price",
    selector: (row) => row.meta.exam_reset_price,
    sortable: true,
    reorder: true,
    compact:true,
    width: "130px",
    center:true,

  },

 {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.exam_details}${row.slug}`}>View</Link>,

  }
];

export default function AllExam() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [jsonArrange, setJSONArrange] = React.useState("")
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_paid_exam)
    .then((res) => {
        var finaljson=[];
        // for (let i = 0; i < res.data.length; i++) {
        //   var json;
        //   json = `${JSON.stringify(res.data[i].meta.exam_question_block)}`
        //   finaljson.push(
        //     {
        //       "status": "publish",
        //       "title": res.data[i].title.rendered,
        //       "meta": {
        //         "exam_time": res.data[i].meta.exam_time,
        //         "exam_type": res.data[i].meta.exam_type,
        //         "exam_details": res.data[i].meta.exam_details,
        //         "available_unavailable": res.data[i].meta.available_unavailable,
        //         "featured_image": res.data[i].meta.featured_image,
        //         "exam_question_block_text": json
        //       }
        //     }
        //   )
        //   setJSONArrange(finaljson)
        // }

        // setJSONArrange(json)
        // console.log(res.data)
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")

      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 200,
      _fields: "id,title,slug,date,meta.exam_time,meta.available_unavailable,meta.exam_type,meta.exam_price,meta.exam_reset_price"
      // _fields: "id,title,slug,date,meta"
    })

  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Exam</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Exams
        </div>
      </div>
      <a href={slugs.new_exam}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Exam</div>
      </a>
      <div style={{ backgroundColor: "#fff", padding: "10px", width: "100%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={CustomersList}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
        />
      </div>
      {/* <textarea rows={50} cols={200} style={{ width: "900px" }}
        value={JSON.stringify(jsonArrange)} placeholder="Exam Details"
        onChange={(event) => { setJSONArrange(event.target.value) }}
      /> */}
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}