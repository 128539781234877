import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";
import store from './redux/store';

import { getUser } from './redux/action';
import { Provider, useDispatch, useSelector } from 'react-redux';
import Header from './Path/Header';
import NotFound from './Path/NotFound';
import SideBar from './Path/SideBar';

import { slugs } from './Constant/slugs';
import AllDailyExam from './Path/Admin/DailyExam';
import AllDailyResults from './Path/Admin/DailyResults';
import AllDemoExam from './Path/Admin/DemoExam';
import AllExam from './Path/Admin/Exam';
import AllResults from './Path/Admin/Results';
import DailyExamDetails from './Path/Admin/DailyExam/DailyExamDetails';
import DailyResultDetails from './Path/Admin/DailyResults/ResultsDetails';
import DemoExamDetails from './Path/Admin/DemoExam/DemoExamDetails';
import ExamDetails from './Path/Admin/Exam/ExamDetails';
import ResultDetails from './Path/Admin/Results/ResultsDetails';
import NewDailyExam from './Path/Admin/DailyExam/DailyExamAddNew';
import NewDemoExam from './Path/Admin/DemoExam/DemoExamAddNew';
import NewExam from './Path/Admin/Exam/ExamAddNew';
import AllStudents from './Path/Admin/Students';
import StudentDetails from './Path/Admin/Students/StudentDetails';
import NewStudent from './Path/Admin/Students/StudentAddNew';
import AllPaidExamList from './Path/Student/Exam';
import AllDemoExamList from './Path/Student/DemoExam';
import AllDailyExamList from './Path/Student/DailyExam';
import LoginScreen from './Path/SignIn';
import StartExam from './Path/Student/StartExam';
import StartDemoExam from './Path/Student/StartDemoExam';
import ExamResult from './Path/Student/ExamResult';
import StartDailyExam from './Path/Student/StartDailyExam';
import DailyExamResult from './Path/Student/ExamResult/DailyExam';
import ExamAllResult from './Path/Student/AllResults';
import DailyExamAllResult from './Path/Student/AllResults/DailyResults';
import Profile from './Path/Profile';
import AllBatch from './Path/Admin/Batch';
import BatchDetails from './Path/Admin/Batch/BatchDetails';
import NewBatch from './Path/Admin/Batch/BatchAddNew';
import Dashboard from './Path/Student/Dashboard';
import AllPaidExamOrders from './Path/Admin/ExamOrders';
import PaidExamOrdersDetails from './Path/Admin/ExamOrders/ExamOrderDetails';
import NewPaidExamOrder from './Path/Admin/ExamOrders/ExamOrderNew';
import ExamExamPayment from './Path/Student/Exam/ExamPayment';
import PaymentVerification from './Path/Student/Exam/PaymentVerification';
import PaymentVerificationKhalti from './Path/Student/Exam/PaymentVerificationKhalti';
import AllBatchOrders from './Path/Admin/BatchOrders';
import BatchOrdersDetails from './Path/Admin/BatchOrders/BatchOrderDetails';
import NewBatchOrder from './Path/Admin/BatchOrders/BatchOrderNew';
import BatchPayment from './Path/Student/DailyExam/BatchPayment';
import PaymentVerificationKhaltiBatch from './Path/Student/DailyExam/PaymentVerificationKhalti';
import PaymentVerificationBatch from './Path/Student/DailyExam/PaymentVerificationEsewaBatch';
import BatchExam from './Path/Student/DailyExam/BatchDetails';
import AllInstitute from './Path/Admin/Institute';
import InstituteDetails from './Path/Admin/Institute/InsitituteDetails';
import NewInstitute from './Path/Admin/Institute/InstituteAddNew';
import InstituteAllDailyExam from './Path/Institute/DailyExam';
import InstituteDailyExamDetails from './Path/Institute/DailyExam/DailyExamDetails';
import InstituteNewDailyExam from './Path/Institute/DailyExam/DailyExamAddNew';
import InstituteAllBatch from './Path/Institute/Batch';
import InstituteBatchDetails from './Path/Institute/Batch/BatchDetails';
import InstituteNewBatch from './Path/Institute/Batch/BatchAddNew';
import InstituteAllBatchOrders from './Path/Institute/BatchOrders';
import InstituteBatchOrdersDetails from './Path/Institute/BatchOrders/BatchOrderDetails';
import InstituteNewBatchOrder from './Path/Institute/BatchOrders/BatchOrderNew';
import AllInstituteBatchOrderAdmin from './Path/Admin/InstituteBatchOrder';
import InstituteBatchOrderDetailsAdmin from './Path/Admin/InstituteBatchOrder/InstituteBatchOrderDetails';
import NewInstituteBatchOrder from './Path/Admin/InstituteBatchOrder/instituteBatchOrderNew';
import InstituteExamOrderDetailsAdmin from './Path/Admin/InstituteExamOrder/InstituteExamOrderDetails';
import AllInstituteExamOrderAdmin from './Path/Admin/InstituteExamOrder';
import NewInstituteExamOrder from './Path/Admin/InstituteExamOrder/InstituteExamOrderNew';
import InstituteDetailsUSer from './Path/Student/InstituteList/details';
import AllInstituteListUser from './Path/Student/InstituteList';

import InstitutePaymentVerificationExam from './Path/Institute/DailyExam/Components/PaymentVerification';

import InstitutePaymentVerificationBatch from './Path/Institute/Batch/Components/PaymentVerification';
import AllDailyResultsInstitute from './Path/Institute/DailyResults';
import AllStudentsInstitute from './Path/Institute/Students';
import StudentDetailsInstitute from './Path/Institute/Students/StudentDetails';
import NewStudentInstitute from './Path/Institute/Students/StudentAddNew';


export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <AppMain />
        </Router>
      </Provider>
    );
  }
}

function AppMain() {
  const [loginState, setLoginState] = React.useState(true)
  const dispatch = useDispatch()
  const myState = useSelector((state) => state.LocationState);
  const Login = useSelector((state) => state.LoginState);
  const location = useLocation()

  const [menuExpand, setMenuExpand] = React.useState(false)
  useEffect(() => {
    dispatch(getUser())
    setLoginState(Login.isLoading)
  }, [])
  return (
    <div className="overflow-x-hidden" >
      {Login.token == null ?
        <LoginScreen />
        :
        <div className="row" >
          {!location.pathname.includes("start") &&
            <div className="sidebar-width-expand">
              <div className={"sidebar-design"}>
                <SideBar menuExpand={menuExpand} />
              </div>
            </div>
          }

          {menuExpand &&
            <div className="sidebar-small-screen">
              <div className={"sidebar-design"} >
                <SideBar setMenuExpand={setMenuExpand} />
              </div>
            </div>
          }

          <div className={!location.pathname.includes("start") ? "main-width-expand" : "main-width-full"} style={{ backgroundColor: "#ddd" }}>
            <Header setMenuExpand={setMenuExpand} menuExpand={menuExpand} />
            <div>
              {Login.userRole == "author" &&
                <Switch>
                  <Route exact path="/"><Dashboard /></Route>
                  {/* <Route exact path="/"><AllExam /></Route> */}

                  <Route exact path="/profile"><Profile /></Route>
                  <Route path={slugs.all_daily_exam}><AllDailyExam /></Route>
                  <Route path={slugs.all_daily_results}><AllDailyResults /></Route>
                  <Route path={slugs.all_demo_exam}><AllDemoExam /></Route>
                  <Route path={slugs.all_exam}><AllExam /></Route>
                  <Route path={slugs.all_results}><AllResults /></Route>
                  <Route path={slugs.all_students}><AllStudents /></Route>
                  <Route path={slugs.all_batch}><AllBatch /></Route>
                  <Route path={slugs.all_orders}><AllPaidExamOrders /></Route>
                  <Route path={slugs.all_batch_orders}><AllBatchOrders /></Route>
                  <Route path={slugs.all_institute}><AllInstitute /></Route>
                  <Route path={slugs.all_institute_batch_orders}><AllInstituteBatchOrderAdmin /></Route>
                  <Route path={slugs.all_institute_exam_orders}><AllInstituteExamOrderAdmin /></Route>

                  <Route path={`${slugs.daily_exam_details}:slug`}><DailyExamDetails /></Route>
                  <Route path={`${slugs.daily_result_details}:slug`}><DailyResultDetails /></Route>
                  <Route path={`${slugs.demo_exam_details}:slug`}><DemoExamDetails /></Route>
                  <Route path={`${slugs.exam_details}:slug`}><ExamDetails /></Route>
                  <Route path={`${slugs.result_details}:slug`}><ResultDetails /></Route>
                  <Route path={`${slugs.order_details}:slug`}><PaidExamOrdersDetails /></Route>
                  <Route path={`${slugs.student_details}:slug`}><StudentDetails /></Route>
                  <Route path={`${slugs.batch_details}:slug`}><BatchDetails /></Route>
                  <Route path={`${slugs.batch_order_details}:slug`}><BatchOrdersDetails /></Route>
                  <Route path={`${slugs.institute_details}:slug`}><InstituteDetails /></Route>
                  <Route path={`${slugs.institute_batch_order_details}:slug`}><InstituteBatchOrderDetailsAdmin /></Route>
                  <Route path={`${slugs.institute_exam_order_details}:slug`}><InstituteExamOrderDetailsAdmin /></Route>
                  <Route path={slugs.new_institute_batch_order}><NewInstituteBatchOrder /></Route>
                  <Route path={slugs.new_institute_exam_order}><NewInstituteExamOrder /></Route>

                  <Route path={slugs.new_daily_exam}><NewDailyExam /></Route>
                  <Route path={slugs.new_demo_exam}><NewDemoExam /></Route>
                  <Route path={slugs.new_exam}><NewExam /></Route>
                  <Route path={slugs.new_order}><NewPaidExamOrder /></Route>
                  <Route path={slugs.new_student}><NewStudent /></Route>
                  <Route path={slugs.new_batch}><NewBatch /></Route>
                  <Route path={slugs.new_batch_order}><NewBatchOrder /></Route>
                  <Route path={slugs.new_institute}><NewInstitute /></Route>

                  <Route path={slugs.exam}><AllPaidExamList /></Route>
                  <Route path={slugs.demo_exam}><AllDemoExamList /></Route>
                  <Route path={slugs.daily_exam}><AllDailyExamList /></Route>

                  <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>
                  <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>
                  <Route path={`${slugs.start_daily_exam}/:slug`}><StartDailyExam /></Route>


                  <Route path={`/batch_payment/:id`}><PaymentVerificationBatch /></Route>
                  <Route path={`/batch_payment_khalti/:id`}><PaymentVerificationKhaltiBatch /></Route>
                  <Route path={`${slugs.batch_exam}/:id`}><BatchExam /></Route>
                  <Route path={`${slugs.batch_payment}/:id`}><BatchPayment /></Route>

                  <Route path={`/exam_payment/:id`}><PaymentVerification /></Route>
                  <Route path={`/exam_payment_khalti/:id`}><PaymentVerificationKhalti /></Route>
                  <Route path={`${slugs.exam_payment}/:id`}><ExamExamPayment /></Route>


                  <Route path={slugs.exam_result}><ExamResult /></Route>
                  <Route path={slugs.daily_exam_result}><DailyExamResult /></Route>

                  <Route path={slugs.exam_result_list}><ExamAllResult /></Route>
                  <Route path={slugs.daily_exam_result_list}><DailyExamAllResult /></Route>

                  <Route path={`${slugs.institute_list}`}><AllInstituteListUser /></Route>
                  <Route path={`${slugs.institute_details_user}`}><InstituteDetailsUSer /></Route>

                  <Route path="*"><NotFound /></Route>
                </Switch>
              }
             {Login.userRole == "institute" &&
                <Switch>
                  <Route exact path="/"><Dashboard /></Route>
                  <Route exact path="/profile"><Profile /></Route>

                  <Route path={slugs.exam}><AllPaidExamList /></Route>
                  <Route path={slugs.demo_exam}><AllDemoExamList /></Route>
                  <Route path={slugs.daily_exam}><AllDailyExamList /></Route>

                  <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>
                  <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>
                  <Route path={`${slugs.start_daily_exam}/:slug`}><StartDailyExam /></Route>

                  <Route path={`/batch_payment/:id`}><PaymentVerificationBatch /></Route>
                  <Route path={`/batch_payment_khalti/:id`}><PaymentVerificationKhaltiBatch /></Route>
                  <Route path={`${slugs.batch_exam}/:id`}><BatchExam /></Route>
                  <Route path={`${slugs.batch_payment}/:id`}><BatchPayment /></Route>

                  <Route path={`/exam_payment/:id`}><PaymentVerification /></Route>
                  <Route path={`/exam_payment_khalti/:id`}><PaymentVerificationKhalti /></Route>
                  <Route path={`${slugs.exam_payment}/:id`}><ExamExamPayment /></Route>

                  <Route path={slugs.exam_result}><ExamResult /></Route>
                  <Route path={slugs.daily_exam_result}><DailyExamResult /></Route>

                  <Route path={slugs.exam_result_list}><ExamAllResult /></Route>
                  <Route path={slugs.daily_exam_result_list}><DailyExamAllResult /></Route>

                  <Route path={slugs.all_institute_daily_exam}><InstituteAllDailyExam /></Route>
                  <Route path={slugs.institute_daily_exam_details}><InstituteDailyExamDetails /></Route>
                  <Route path={slugs.new_institute_daily_exam}><InstituteNewDailyExam /></Route>
                 
                  <Route path={`${slugs.institute_list}`}><AllInstituteListUser /></Route>
                  <Route path={`${slugs.institute_details_user}`}><InstituteDetailsUSer /></Route>

                  <Route path={slugs.all_institute_batch}><InstituteAllBatch /></Route>
                  <Route path={slugs.institute_batch_details}><InstituteBatchDetails /></Route>
                  <Route path={slugs.new_batch_institute}><InstituteNewBatch /></Route>

                  <Route path={slugs.all_institute_batch_orders}><InstituteAllBatchOrders /></Route>
                  <Route path={slugs.institute_batch_order_details}><InstituteBatchOrdersDetails /></Route>
                  <Route path={slugs.new_institute_batch_order}><InstituteNewBatchOrder /></Route>

                  <Route path={slugs.all_institute_batch_orders}><InstituteAllBatchOrders /></Route>
                  <Route path={slugs.institute_batch_order_details}><InstituteBatchOrdersDetails /></Route>

                  <Route path={"/all-institute-batch-result"}><AllDailyResultsInstitute /></Route>
                  <Route path={slugs.institute_batch_order_details}><InstituteBatchOrdersDetails /></Route>
                  <Route path={`${slugs.result_admin_view}/:slug`}><DailyExamResult /></Route>


                  <Route path={`${slugs.exam_payment_insititute_verification}/:id`}><InstitutePaymentVerificationExam /></Route>
                  <Route path={`${slugs.batch_payment_institute_verification}/:id`}><InstitutePaymentVerificationBatch /></Route>
                  <Route path={slugs.all_students_institute}><AllStudentsInstitute /></Route>
                  <Route path={slugs.new_student_institute}><NewStudentInstitute /></Route>
                  <Route path={`${slugs.student_details_institute}:slug`}><StudentDetailsInstitute /></Route>

             
                  <Route path="*"><NotFound /></Route>
                </Switch>
              }
             {Login.userRole == "student" || Login.userRole == "subscriber" &&
                <Switch>
                  <Route exact path="/"><Dashboard /></Route>
                  <Route exact path="/profile"><Profile /></Route>

                  <Route path={slugs.exam}><AllPaidExamList /></Route>
                  <Route path={slugs.demo_exam}><AllDemoExamList /></Route>
                  <Route path={slugs.daily_exam}><AllDailyExamList /></Route>

                  <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>
                  <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>
                  <Route path={`${slugs.start_daily_exam}/:slug`}><StartDailyExam /></Route>

                  <Route path={`${slugs.institute_list}`}><AllInstituteListUser /></Route>
                  <Route path={`${slugs.institute_details_user}`}><InstituteDetailsUSer /></Route>

                  <Route path={`/batch_payment/:id`}><PaymentVerificationBatch /></Route>
                  <Route path={`/batch_payment_khalti/:id`}><PaymentVerificationKhaltiBatch /></Route>
                  <Route path={`${slugs.batch_exam}/:id`}><BatchExam /></Route>
                  <Route path={`${slugs.batch_payment}/:id`}><BatchPayment /></Route>

                  <Route path={`/exam_payment/:id`}><PaymentVerification /></Route>
                  <Route path={`/exam_payment_khalti/:id`}><PaymentVerificationKhalti /></Route>
                  <Route path={`${slugs.exam_payment}/:id`}><ExamExamPayment /></Route>

                  <Route path={slugs.exam_result}><ExamResult /></Route>
                  <Route path={slugs.daily_exam_result}><DailyExamResult /></Route>

                  <Route path={slugs.exam_result_list}><ExamAllResult /></Route>
                  <Route path={slugs.daily_exam_result_list}><DailyExamAllResult /></Route>

                  <Route path="*"><NotFound /></Route>
                </Switch>
              }
            </div>
          </div>
        </div>
      }

    </div >
  )
}
