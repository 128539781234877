import { LOCATION, LOGIN, LOGOUT, RETRIEVE_TOKEN, STORE_NONCE } from './types';

export const login = (
  id,
  userName,
  token,
  userEmail,
  userRole,
  userPhoto,
  userPhoneNumber,
  userBatch
) => {
  localStorage.setItem('token1', token);
  localStorage.setItem('id', id);
  localStorage.setItem('userName', userName);
  localStorage.setItem('userEmail', userEmail);
  localStorage.setItem('userPhoto', userPhoto);
  localStorage.setItem('userPhoneNumber', userPhoneNumber);
  localStorage.setItem('userRole', userRole);
  localStorage.setItem('userBatch', userBatch);
  return ({
    type: LOGIN,
    userID: id,
    userName: userName,
    token: token,
    userEmail: userEmail,
    userRole: userRole,
    userPhoto: userPhoto,
    userPhoneNumber: userPhoneNumber,
    userBatch: userBatch,
    isLoading: false,
  })
}
export const logout = () => {
  localStorage.removeItem('token1');
  localStorage.removeItem('id');
  localStorage.removeItem('userName');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('userPhoto');
  localStorage.removeItem('userPhoneNumber');
  localStorage.removeItem('userRole');
  localStorage.removeItem('userBatch');
  return {
    type: LOGOUT,
    userID: null,
    userName: null,
    token: null,
    userEmail: null,
    userPhoto: null,
    userRole: null,
    userPhoneNumber: null,
    userBatch: null,
    isLoading: false
  }
}
export const getUser = () => {
  const token = localStorage.getItem('token1');
  const id = localStorage.getItem('id');
  const userName = localStorage.getItem('userName');
  const userEmail = localStorage.getItem('userEmail');
  const userPhoto = localStorage.getItem('userPhoto');
  const userPhoneNumber = localStorage.getItem('userPhoneNumber');
  const userRole = localStorage.getItem('userRole');
  const userBatch = localStorage.getItem('userBatch');
  return {
    type: RETRIEVE_TOKEN,
    userID: id,
    userName: userName,
    token: token,
    userEmail: userEmail,
    userPhoto: userPhoto,
    userPhoneNumber: userPhoneNumber,
    userRole: userRole,
    userBatch: userBatch,
    isLoading: false
  }
}


export const Location = (lat, lng, district, id, province, provinceId) => {
  return {
    type: LOCATION,
    lat: lat,
    lng: lng,
    district: district,
    id: id,
    province: province,
    provinceId: provinceId
  }
}

export const storeNonce = (NonceValue) => ({
  type: STORE_NONCE,
  nonce: NonceValue
})

