import axios from 'axios';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NodePOSTApi } from '../../../../Constant/api';
import { slugs } from '../../../../Constant/slugs';

export default function InstitutePaymentVerificationExam(props) {
  const location = useLocation()
  const history = useHistory()

  const [reload, setReload] = React.useState(false)
  const [responseReturn, setResponseReturn] = React.useState(true)

  const TESTSuccess = () => {
    const pid = localStorage.getItem('pid')
    const rid = location.search.slice(location.search.search("refId") + 6)
    const Amount = location.search.slice(location.search.search("amt") + 4, location.search.search("refId") - 1)
    const ExamID = location.pathname.slice(slugs.exam_payment_insititute_verification.length + 1)

    setReload(true)
    // axios({
    //   method: "POST",
    //   url: `http://localhost:4000/esewa_payment_verification_institute_exam`,
    //   data: {
    //     pid: pid,
    //     rid: rid,
    //     Amount: Number(Amount),
    //     examID: ExamID,
    //     buy: location.search.slice(location.search.search("buy") + 4, location.search.search("exam") - 1)
    //   }
    // })
      NodePOSTApi({
        pid: pid,
        rid: rid,
        Amount: Amount,
        examID: ExamID,
        buy: location.search.slice(location.search.search("buy") + 4, location.search.search("exam") - 1)
      }, "esewa_payment_verification_institute_exam")
      .then((res) => {
        console.log('Success:', res.data);
        setResponseReturn(res.data.success)
        setReload(false)
        if (res.data.success) {
          history.push(`${slugs.all_institute_daily_exam}`)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    if (
      location.search.search('refId') !== -1 &&
      location.search.search('oid') !== -1 &&
      location.search.search('amt') !== -1 &&
      location.search.search('buy') !== -1
    )
      TESTSuccess()
  }, [])
  return (
    <div>
      {/* {location.search.slice(location.search.search("buy") + 4, location.search.search("exam") - 1)} */}
      {!responseReturn &&
        "Contact Admin for further process"

      }
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}