import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, UsersGETApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import NotFound from "../../NotFound";
import FormComponent from "./Components/Form";

export default function BatchOrdersDetails(props) {
  const [NotFoundData, setNotFound] = React.useState(false)
  const [FetchDone, setFetchDone] = React.useState(false)
  const [FetchData, setFetchData] = React.useState({})
  const location = useLocation()
  const history = useHistory()
  const AdsFetch = (slug) => {
    AdsGETApiAuth({
      slug: slug,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_batch_orders)
      .then(res => {
        console.log(res.data)
        if (res.data.length == 0)
          setNotFound(true)
        else {
          setFetchData(res.data[0])
        }
        setTimeout(() => {
          setFetchDone(true)
        }, 100)
      }).catch((e) => {
        console.log(e)
        setNotFound(true)
        setTimeout(() => {
          setFetchDone(true)
        }, 100)
      })
  }
  useEffect(() => {
    AdsFetch(location.pathname.slice(slugs.batch_order_details.length))
    return history.listen((location) => {
      if (location.pathname.includes(slugs.batch_order_details)) {
        window.scrollTo(0, 0)
        AdsFetch(location.pathname.slice(slugs.batch_order_details.length))
      }
    })
  }, [history])
  return (
    <div>
      {FetchDone ?
        <div>
          {NotFoundData ?
            <NotFound /> :
            <div>
              <div className="row" style={{ padding: "30px 30px" }}>
                <div className="col-md-8 button">
                  <h2>{FetchData.title.rendered}.</h2>
                </div>
                <div className="col-md-4" style={{ textAlign: 'right' }}>
                  <a href="/">Dashboard</a> / <a href={slugs.all_orders}>All Orders</a>
                </div>
              </div>
              <FormComponent
                FetchData={FetchData}
              />
            </div>
          }
        </div> :
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}