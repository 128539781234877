import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { login, logout } from '../../../redux/action';
import store from '../../../redux/store';

import { ExamBlock } from '../ExamBlock';

const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}
export default function InstituteBatchList(props) {
  const [AllBatches, setAllBatches] = React.useState([]);
  const [AllBatchesList, setAllBatchesList] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [AllBatchOrders, setAllBatchOrders] = React.useState([]);
  const [MyBatchOrders, setMyBatchOrders] = React.useState([]);
  const [UnverifiedBatchOrders, setUnverifiedBatchOrders] = React.useState([]);
  const [NewBatchOrders, setNewBatchOrders] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [choose, setChoose] = React.useState(1);
  const dispatch = useDispatch()
  const id = store.getState().LoginState.userID

  const fetchExam = (setAllExamList, perPage) => {
    setReload(true)
    NodePOSTApi({ userID: id, instituteID: `${props.instituteID}` }, "institute-batches")
      .then((res) => {
        console.log(res.data)
        setError(res.data.error)
        setAllBatchOrders(res.data.my_batches)
        setAllBatches(res.data.batch_data)
        setReload(false)
        const myBatchesArray = res.data.my_batches.filter(a => a.meta.verification_status == "Verified")
        var MyBatchArr = [];
        var UnverifiedBatchArr = [];
        var NewBatchArray = [];
        for (let i = 0; i < res.data.batch_data.length; i++) {
          const batchDataJson = res.data.batch_data[i]
          const BatchFilter = res.data.my_batches.filter(b => b.meta.batch_id_order == batchDataJson.id)
          if (BatchFilter.length !== 0) {
            if (BatchFilter[0].meta.verification_status == "Verified") {
              MyBatchArr.push(batchDataJson)
              setMyBatchOrders(MyBatchArr)
            }
            else {
              UnverifiedBatchArr.push(batchDataJson)
              setUnverifiedBatchOrders(UnverifiedBatchArr)
            }
          }
          else {
            NewBatchArray.push(batchDataJson)
            setNewBatchOrders(NewBatchArray)
          }
        }
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
  }
  const location = useLocation()
  const LoginUser = useSelector((state) => state.LoginState);
  useEffect(() => {
    fetchExam()
  }, [])

  const BatchesBlock = ({ item, index }) => {
    const MyBatchFound = AllBatchOrders.filter(a => a.meta.batch_id_order == item.id)
    return (
      <div>
        <div style={{
          padding: "5px 0", margin: "5px 0px", border: "1.5px solid #bbb",
          borderRadius: "5px",
        }} className="row">
          <div style={{ width: "100%", }}>
            <div style={{ textAlign: "left", fontSize: "12px" }}>

              <table>
                <tbody>
                  <tr>
                    <td className="col-6">Name</td>
                    <td className="col-6"> {item.meta.batch_name}</td>
                  </tr>
                  <tr>
                    <td className="col-6">Batch Price</td>
                    <td className="col-6">Rs. {item.meta.batch_price}</td>
                  </tr>
                  {item.meta.batch_institute_name !== "" &&
                    <tr>
                      <td className="col-6">Batch by</td>
                      <td className="col-6">{item.meta.batch_institute_name}</td>
                    </tr>
                  }
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      {MyBatchFound.length == 0 ?
                        <span>
                          {item.meta.batch_allow_buy == "Yes" || item.meta.batch_institute_id == "13748" ?
                            <div className="row">
                              <Link to={`${slugs.batch_payment}/${item.slug}`}
                                style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                <div className="sign-in-button-12" style={{ width: "50%", backgroundColor: "#000" }}>
                                  Buy Batch
                                </div>
                              </Link>
                            </div>
                            :
                            <div className="row">
                              <Link to={`${slugs.institute_details_user}${item.meta.batch_institute_id}`}
                                style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                <div className="sign-in-button-12" style={{ width: "50%", backgroundColor: "#00f" }}>
                                  Contact Institute
                                </div>
                              </Link>
                            </div>
                          }
                        </span>
                        :
                        <>
                          {MyBatchFound[0].meta.verification_status == "Verified" ?
                            <span>
                              <div className="row">
                                <Link to={`${slugs.batch_exam}/${item.slug}`}
                                  style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                  <div className="sign-in-button-12" style={{ width: "50%" }}>
                                    View Batch
                                  </div>
                                </Link>
                              </div>
                            </span>
                            :
                            <span>
                              <div className="row">
                                <Link to={`${slugs.batch_payment}/${item.slug}`}
                                  style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                  <div className="sign-in-button-12" style={{ width: "50%", backgroundColor: "#000" }}>
                                    Pay Now
                                  </div>
                                </Link>
                              </div>
                            </span>
                          }
                        </>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div >
    )
  }

  return (
    <div>
      <div className="row" style={{ margin: "10px" }}>
        {AllBatches.map((item, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-12 center" style={{ padding: "0 5px" }}>
            <BatchesBlock item={item} index={index} />
          </div>
        ))}
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}