import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { slugs } from "../../../Constant/slugs";
import { AdsGETApiAuth, NodePOSTApi, NodePOSTLocalApi, NodePUTApi, NodePUTLocalApi } from "../../../Constant/api";
import { useSelector } from "react-redux";
import { CustomButton } from "../../CustomButtom";
import ImageUpload from "../../ImageUpload";

const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    compact: true, padding: 0, width: "75px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
    compact: true, padding: 0
  },
  {
    id: 32,
    name: "Exam Time",
    selector: (row) => row.meta.exam_time,
    sortable: true,
    reorder: true,
    center: true,
    compact: true, padding: 0,
  },
  {
    id: 32,
    name: "Date",
    selector: (row) => row.date.slice(0, 10),
    sortable: true,
    compact: true, padding: 0,
    reorder: true
  },
  {
    id: 33,
    name: "Allow Access",
    selector: (row) => row.meta.allow_access,
    sortable: true,
    reorder: true,
    compact: true, padding: 0,
    width: "150px"

  },
  {
    id: 34,
    name: "Type",
    selector: (row) => row.meta.exam_type,
    sortable: true,
    compact: true, padding: 0,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    compact: true, padding: 0,
    cell: (row) => <Link to={`${slugs.institute_daily_exam_details}${row.slug}`}>View</Link>,

  }
];

export default function InstituteAllDailyExam() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [ordersList, setOrderList] = React.useState([])
  const [TotalPaid, setTotalPaid] = React.useState(0)
  const [BuyNow, setBuyNow] = React.useState(false)
  const [TotalCount, setTotalCount] = React.useState(0)
  const [TotalOrders, setTotalOrders] = React.useState(0)
  const [Reload, setReload] = React.useState(false)
  const Login = useSelector((state) => state.LoginState);
  const [ExamPrice, setExamPrice] = React.useState("")
  const [VerificationMethod, setVerificationMethod] = React.useState("Esewa")
  const [VerificationID, setVerificationID] = React.useState("")
  const [pid, setPid] = React.useState("")
  const [PhoneNumber, setPhoneNumber] = React.useState(Login.userPhoneNumber)
  const [Email, setEmail] = React.useState(Login.userEmail)
  const instituteID = localStorage.getItem('instituteID');
  const instituteName = localStorage.getItem('instituteName');
  const [OrderID, setOrderID] = React.useState("")
  const [OrderModal, setOrderModal] = React.useState(false)
  const [BatchCount, setBatchCount] = React.useState(0)
  const location = useLocation()

  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_daily_exam)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    const instituteID = localStorage.getItem('instituteID');
    GetAllProjects({
      per_page: 500,
      _fields: "id,title,slug,date,meta.exam_time,meta.allow_access,meta.exam_type,meta.batch_name",
      "filter[meta_query][1][key]": "batch_institute_id",
      "filter[meta_query][1][value][0]": instituteID,
      "filter[meta_query][1][compare]": "=",
      "filter[meta_query][relation]": "AND",
    })
    fetchInstituteBatchOrders()
  }, [])
  const fetchInstituteBatchOrders = () => {
    NodePOSTApi({
      instituteId: instituteID,
    }, "institute-exam-orders")
      .then(res => {
        setOrderList(res.data.my_batches)
        var count = 0;
        var paid = 0;
        var orders = 0;
        for (let i = 0; i < res.data.my_batches.length; i++) {
          const item = res.data.my_batches[i].meta;
          console.log(count, paid)
          if (item.verification_status == "Verified") {
            orders++;
            paid += Number(item.paid_amount);
            count += Number(item.exam_count_order)
            setTotalOrders(orders)
            setTotalPaid(paid)
            setTotalCount(count)
            console.log(count, paid)
          }
        }
      })
      .catch((err) => {
      })
  }
  const myform = React.useRef(null)
  function generateRandomNumber() {
    var minm = 1000;
    var maxm = 9999;
    return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
  }
  const BuyExamHandle = () => {
    var data;
    data = {
      "title": `${Login.userID}-${instituteID}`,
      "status": "publish",
      meta: {
        "user_id_order": `${Login.userID}`,
        "user_email_order": `${Login.userEmail}`,
        "user_phone_order": `${PhoneNumber}`,
        "user_username_order": `${Login.userName}`,
        "paid_amount": "0",
        "amount_to_pay": `${ExamPrice}`,
        "verification_status": "Unverified",
        "verification_method": VerificationMethod,
        "verification_date": "",
        "verification_id": `${VerificationID}`,
        "exam_count_order": `${BatchCount}`,
        "batch_institute_name": instituteName,
        "batch_institute_id": instituteID
      }
    }
    NodePOSTApi({ orderData: JSON.stringify(data) }, "buy-exam-quota")
      .then(res => {
        console.log(res.data)
        setOrderID(res.data.id)
        if (VerificationMethod == "Manual") {
          window.location.reload()
        }
        else if (VerificationMethod == "Esewa") {
          const RandNum = generateRandomNumber()
          setPid(`${res.data.id}-${RandNum}`)
          setTimeout(() => {
            localStorage.setItem("pid", `${res.data.id}-${RandNum}`)
            localStorage.setItem("AmountPaid", `${ExamPrice}`)
            myform.current.submit()
          }, 100)
        }
      }
      )
  }

  const PayNowHandle = (id, verify) => {
    var data;
    data = {
      meta: {
        "verification_id": `${VerificationID}`,
      }
    }
    NodePUTApi({ orderData: JSON.stringify(data), id: OrderID }, "buy-exam-quota")
      .then(res => {
        console.log(res.data)
        if (VerificationMethod == "Manual") {
          window.location.reload()
        }
        else if (VerificationMethod == "Esewa") {
          const RandNum = generateRandomNumber()
          setPid(`${res.data.id}-${RandNum}`)
          setTimeout(() => {
            localStorage.setItem("pid", `${res.data.id}-${RandNum}`)
            localStorage.setItem("AmountPaid", `${ExamPrice}`)
            myform.current.submit()
          }, 100)
        }
      }
      )
  }

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Batch Exams</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Batch Exams
        </div>
      </div>
      <div className="row" style={{ padding: "0px 30px" }}>
        <div className="col-md-8 button">
          <a href={slugs.new_institute_daily_exam}>
            <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
              onClick={() => {

              }}
            >Add New Batch Exam</div>
          </a>
        </div>
      </div>


      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={CustomersList}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
        />
      </div>

      <div className="row" style={{ padding: "10px", margin: "10px" }}>
        <table className="col-lg-4">
          <tbody>
            <tr>
              <td>Total Amount Paid</td>
              <td>Rs. {TotalPaid}</td>
            </tr>
            <tr>
              <td>Total Private Exam Allowed</td>
              <td>{TotalCount}</td>
            </tr>
            <tr>
              <td>Total Private Exam Created</td>
              <td>{CustomersList.filter(a => a.meta.allow_access == "").length}</td>
            </tr>
            <tr>
              <td>Total Orders</td>
              <td>{TotalOrders}</td>
            </tr>
          </tbody>
        </table>
        <div className="col-lg-7" style={{ backgroundColor: "#fff", marginLeft: 10 }}>
          <div style={{ textAlign: "justify", fontSize: "10px", margin: "10px 10px 10px 0px" }}>
            <ul>
              <li>Private exam are available only for the insititute batches.</li>
              <li>If the batches are not private even the admin can use and sell the exam for other batches created by the admin and no % amount will be given to the institute.</li>
              <li> The Public exams made by the admin can also be used and sold for the institute batches by the institute at their suitable price manually and no % will be taken by the admin for this</li>
              <li> The public exams made by admin will not be shown in the exam list</li>
            </ul>
          </div>

        </div>
      </div>
      <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
        onClick={() => {
          setBuyNow(true)
          setOrderModal(true)
          setExamPrice("")
          setBatchCount("")

        }}
      >Buy More Private Exam Quota</div>
      {OrderModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBuyNow(false)
                    setOrderModal(false)
                  }}
                >X</div>
                <table>
                  <tbody>
                    <tr>
                      <td>Payment Method :{OrderID} </td>
                      <td>
                        <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Esewa"} />
                        {/* <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Khalti"} /> */}
                        <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Manual"} />
                      </td>
                    </tr>
                    <tr>
                      <td className="col-3">Batch Count</td>
                      <td className="col-6">
                        <input className="input-common"
                          value={BatchCount} placeholder="Batch Count"
                          onChange={(event) => {
                            setBatchCount(event.target.value.replace(/\D/g, ""))
                            if (event.target.value == 1)
                              setExamPrice(500)
                            else if (event.target.value == 2)
                              setExamPrice(800)
                            else if (event.target.value > 2 && event.target.value <= 10)
                              setExamPrice(event.target.value * 300)
                            else
                              setExamPrice(event.target.value * 200)
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="col-3">Price</td>
                      <td className="col-6">
                        <div className="input-common">
                          {ExamPrice}&nbsp;
                        </div>
                      </td>
                    </tr>
                    {VerificationMethod == "Manual" &&
                      <tr>
                        <td>Verification Image : </td>
                        <td>
                          <div className="col-12">
                            <ImageUpload
                              ImageUrl={VerificationID}
                              setImageUrl={setVerificationID}
                            />
                            {VerificationID == "" &&
                              "Attach slip"}
                          </div>
                        </td>
                      </tr>
                    }
                    <tr>
                      <td colSpan={2}>
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                          {(VerificationID !== "" || VerificationMethod !== "Manual") &&
                            <div className="sign-in-button-12 col-4"
                              onClick={() => {
                                if (BuyNow)
                                  BuyExamHandle()
                                else
                                  PayNowHandle()
                              }}
                            >
                              Continue
                              {VerificationMethod == "Esewa" && " with Esewa"}
                              {VerificationMethod == "Khalti" && " with Khalti"}
                            </div>
                          }
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>
                <div style={{ fontSize: "10px" }}>
                  *Price Format:<br />
                  1 exam: Rs 500,<br />
                  2 exam: Rs 800,<br />
                  3-10 exam: Rs 300 per exam<br />
                  11 and above exam: Rs 200 per exam,<br />

                </div>
              </div>
            </div>
          </div>
        </section>
      }
      <div style={{ padding: "10px", margin: "10px" }}>
        <table className="col-lg-9">
          <tbody>
            <tr>
              <td>SN</td>
              <td>Amount Paid</td>
              <td>Amount To Pay</td>
              <td>Status</td>
              <td>Count</td>
              <td>Date</td>
              <td></td>
            </tr>
            {ordersList.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.meta.paid_amount}</td>
                <td>{item.meta.amount_to_pay}</td>
                <td>{item.meta.verification_status}</td>
                <td>{item.meta.exam_count_order}</td>
                <td>{item.meta.verification_date}</td>
                <td
                  onClick={() => {
                    setBuyNow(false)
                    setExamPrice(item.meta.amount_to_pay)
                    setBatchCount(item.meta.exam_count_order)
                    setOrderID(item.id)
                    setOrderModal(true)
                    setVerificationMethod(item.meta.verification_method)
                  }}
                >{item.meta.verification_status == "Unverified" &&
                  "Pay Now"
                  }</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {/* <form ref={myform} action="https://esewa.com.np/epay/main" method="POST"> */}
        <form ref={myform} action="https://uat.esewa.com.np/epay/main" method="POST">
        <input value={ExamPrice} name="tAmt" type="hidden" />
        <input value={ExamPrice} name="amt" type="hidden" />
        <input value="0" name="txAmt" type="hidden" />
        <input value="0" name="psc" type="hidden" />
        <input value="0" name="pdc" type="hidden" />
        {/* <input value="NP-ES-EPSEXAM" name="scd" type="hidden" /> */}
        <input value="EPAYTEST" name="scd" type="hidden" />
        <input value={pid} name="pid" type="hidden" />
        <input value={`http://localhost:3006${slugs.exam_payment_insititute_verification}/${OrderID}?buy=true`} type="hidden" name="su" />
        {/* <input value={`https://ubt.skysewa.com${slugs.exam_payment_insititute_verification}/${OrderID}?buy=${BuyNow}&exam=${location.pathname.slice(slugs.exam_payment.length + 1)}`} type="hidden" name="su" /> */}
        <input value={`https://ubt.skysewa.com${location.pathname}`} type="hidden" name="fu" />
      </form>
    </div>
  )
}
