import axios from 'axios';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';

export default function PaymentVerificationKhalti(props) {
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    // location.search.includes("amt") && location.search.includes("oid")

  })
  const [reload, setReload] = React.useState(false)
  const [responseReturn, setResponseReturn] = React.useState(true)

  const TESTSuccess = () => {
    let params = (new URL(document.location)).searchParams;
    let pidx = params.get('pidx');
    let pid = params.get("purchase_order_id")
    // const pid = localStorage.getItem('pid')
    const rid = location.search.slice(location.search.search("refId") + 6)
    const Amount = params.get("amount")
    const Examslug = location.pathname.slice(21)
    const examArray = Examslug.split("/")
    console.log(examArray)

    setReload(true)
    if (examArray.length > 1)
      NodePOSTApi({
        pidx: pidx,
        Amount: Amount,
        examID: examArray[0],
        pid: pid,
      }, "khalti_payment_verification")

        .then((res) => {
          console.log('Success:', res.data);
          setResponseReturn(res.data.success)
          setReload(false)
          if (res.data.success) {
            history.push(`${slugs.exam_payment}/${examArray[1]}`)
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        }).finally(() => {
          setReload(false)
        })
    else{
      setReload(false)
      setResponseReturn(false)
    }
  }
  useEffect(() => {
    if (
      location.search.search('pidx') !== -1 &&
      location.search.search('amount') !== -1
    )
      TESTSuccess()
  }, [])
  return (
    <div>
      {!responseReturn &&
        "Contact Admin for further process"
      }

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}