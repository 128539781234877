import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { slugs } from "../../../Constant/slugs";
import { AdsGETApiAuth } from "../../../Constant/api";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    compact: true, padding: 0,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
    compact: true, padding: 0,
  },
  {
    id: 3,
    name: "Batch Start",
    selector: (row) => row.meta.batch_start_date,
    sortable: true,
    center:true,
    reorder: true,
    compact: true, padding: 0,
  },
  {
    id: 32,
    name: "Batch End",
    selector: (row) => row.meta.batch_end_date,
    sortable: true,
    compact: true, padding: 0,
    reorder: true
  },
  {
    id: 312,
    name: "Status",
    selector: (row) => row.meta.batch_status,
    sortable: true,
    compact: true, padding: 0,
    reorder: true
  },
 {
    id: 312,
    name: "Price",
    selector: (row) => row.meta.batch_price,
    sortable: true,
    reorder: true,
    width:"100px"
  }, 
  {
    id: 312,
    name: "Discount",
    selector: (row) => row.meta.batch_discount,
    sortable: true,
    reorder: true,
    width:"100px"
  }, 
  {
    id: 312,
    name: "Reset Exam Price",
    selector: (row) => row.meta.exam_reset_price,
    sortable: true,
    compact: true, padding: 0,
    reorder: true,
    width:"100px"
  },
  {
    id: 3,
    name: "View",
    right: true,
    width:"100px",
    cell: (row) => <Link to={`${slugs.batch_details}${row.slug}`}>View</Link>,

  }
];

export default function AllBatch() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_batch)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({ per_page: 100 })

  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Batches</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Batches
        </div>
      </div>
      <a href={slugs.new_batch}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Batch</div>
      </a>
      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={CustomersList}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
        />
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}