import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { slugs } from '../../../Constant/slugs';
const imageHeight = "200px"
export default function Home() {
  const dispatch = useDispatch()
  const [A1, setA1] = React.useState(false)
  const data = [
    {
      slug: slugs.exam,
      image: "https://api.skysewa.com/wp-content/uploads/2023/02/AllExam.jpeg"
    },
    {
      slug: slugs.demo_exam,
      image: "https://api.skysewa.com/wp-content/uploads/2023/02/FreeExam.jpeg"
    },
    {
      slug: slugs.batch_exam,
      image: "https://api.skysewa.com/wp-content/uploads/2023/02/BatchExam.jpeg"
    },
    {
      slug: slugs.exam_result_list,
      image: "https://api.skysewa.com/wp-content/uploads/2023/02/Results.jpeg"
    },
    {
      slug: slugs.daily_exam_result,
      image: "https://api.skysewa.com/wp-content/uploads/2023/02/BatchResults.jpeg"
    },
    {
      slug: "profile",
      image: "https://api.skysewa.com/wp-content/uploads/2023/02/Profile.jpeg"
    },
  ]
  const Block = ({ slug, image }) => {
    return (
      <div className="col-lg-4 col-md-6" >
        <Link style={{
          textDecorationLine: 'none',
          // color: "#000",
        }}
          to={`${slug}`}
          style={{
            display: 'flex',
            justifyContent: 'center', 
          }}
        >

          <div style={{
            backgroundColor: "#ffffff",
            display: 'flex',
            alignContent: 'center', alignItems: 'center',
            padding: "5px", margin: "10px 0px", border: "1px solid black",
            width: "220px", height: "220px",
            borderRadius: "5px"
          }}
            className="home-box center"
          >
            <img
              style={{
                backgroundColor: "#fff", border: "1px solid black",
                width: "220px", height: "220px",
                borderRadius: "5px"
              }}
              src={image}
            />
          </div>
        </Link>

      </div>
    )
  }
  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div className="row" style={{ margin: "10px" }}>
            {data.map((item, index) => (
              <Block slug={item.slug} image={item.image} />
            ))}
          </div>
        </div>
      </div>
    </div >
  )
}