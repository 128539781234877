import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    compact:true,
    width: "100px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
    compact:true,
  },
  {
    id: 32,
    name: "Address",
    selector: (row) => row.meta.institute_address,
    sortable: true,
    reorder: true,
    center:true,
  },
  {
    id: 33,
    name: "City",
    selector: (row) => row.meta.institute_city,
    sortable: true,
    reorder: true,
    width: "150px",
    compact:true,
    center:true,
  },
  {
    id: 331,
    name: "Email",
    selector: (row) => row.meta.institute_email,
    sortable: true,
    reorder: true,
    width: "100px",
    compact:true,
    center:true,
  },
  {
    id: 332,
    name: "Phone Number",
    selector: (row) => row.meta.institute_phone_number_1,
    sortable: true,
    reorder: true,
    compact:true,
    width: "130px",
    center:true,

  },
 {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.institute_details}${row.slug}`}>View</Link>,

  }
];

export default function AllInstitute() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [jsonArrange, setJSONArrange] = React.useState("")
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_institute)
    .then((res) => {
        var finaljson=[];
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")

      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 200,
      _fields: "id,title,slug,date,meta.exam_time,meta.available_unavailable,meta.exam_type,meta.exam_price,meta.exam_reset_price"
      // _fields: "id,title,slug,date,meta"
    })

  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Institute</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Institute
        </div>
      </div>
      {/* <a href={slugs.new_institute}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Institute</div>
      </a> */}
      <div style={{ backgroundColor: "#fff", padding: "10px", width: "100%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={CustomersList}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          pagination
          selectableRows
        />
      </div>
   
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}