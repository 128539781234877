import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';

export default function AllInstituteListUser() {
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [reload, setReload] = React.useState(false)

  const fetchExam = (setAllExamList, perPage) => {
    setReload(true)
    AdsGETApi(perPage, "institute_data")
      .then((res) => {
        setAllExamList(res.data)
        setReload(false)
      })
  }
  let myarray = []

  useEffect(() => {
    fetchExam(setAllExamList1, { per_page: 100, order: "asc" })
  }, [])


  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div className="row" style={{ margin: "10px" }}>
            <h2>All Institute</h2>
            {AllExamList1.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center" style={{ padding: "0 5px" }}>
                <div style={{
                  padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
                  borderRadius: "5px",
                }}>
                  {item.meta.institute_logo !== "" ?
                    <img className="col-12" src={item.meta.institute_logo}
                      style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
                    :
                    <img className="col-12"
                      src={"https://skysewa.com/wp-content/uploads/2023/01/skytech-logo-wo-bg-1536x522.png"}
                      style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
                  }
                  <br />
                  <div>
                    {item.meta.institute_name}&nbsp;
                  </div>

                  <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                    <Link className="sign-in-button-1 col-8 Link"
                      to={`${slugs.institute_details_user}${item.id}`}
                    >
                      <div style={{ color: "#fff" }}>
                        View Details
                      </div>
                    </Link>
                  </div>
                </div >

              </div>
            ))}
          </div>
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}
