export const slugs = {
  "new_book": "/new-book",
  "books_details": "/admin/books/",
  "all_books": "/all-books",
  "db_slug_book": "books",

  "new_chapter_wise_exam": "/new-chapter",
  "chapter_details": "/chapters/",
  "all_chapters": "/all-chapters",
  "db_slug_chapter_wise_exam": "chapter_wise_exam",
  
  "new_daily_exam": "/new-batch-exam",
  "daily_exam_details": "/batch-exams/",
  "all_daily_exam": "/all-batch-exam",
  "db_slug_daily_exam": "daily_exam",
  
  "new_institute": "/new-institute",
  "institute_details": "/institute/",
  "all_institute": "/all-institute",
  "db_slug_institute": "institute_data",

  "new_real_time_exam": "/new-real-time-exam",
  "real_time_exam_details": "/real-time-exams/",
  "all_real_time_exam": "/all-real-time-exam",
  "db_slug_real_time_exam": "real_time_exam",

  "new_student_institute": "/new-student-institute",
  "student_details_institute": "/students-institute/",
  "all_students_institute": "/all-students-institute",

  "new_demo_exam": "/new-demo-exam",
  "demo_exam_details": "/demo-exams/",
  "all_demo_exam": "/all-demo-exam",
  "db_slug_demo_exam": "demo_exam",

  "new_exam": "/new-exam",
  "exam_details": "/exams/",
  "all_exam": "/all-exam",
  "db_slug_paid_exam": "paid_exam",

  "result_details": "/results/",
  "all_results": "/all-results",
  "db_slug_paid_results": "paid_results",

  "new_batch_order": "/new-batch-order",
  "batch_order_details": "/batch-order/",
  "all_batch_orders": "/all-batch-order",
  "db_slug_batch_orders": "batch_order",

  "new_batch_order_institute": "/new-batch-order-institute",
  "batch_order_details_institute": "/batch-order-institute/",
  "all_batch_orders_institute": "/all-batch-order-institute",
  "db_slug_batch_orders_institute": "institute_batchorder",

  "new_order_institute": "/new-exam-order-institute",
  "order_details_institute": "/exam-orderinstitute/",
  "all_order_institutes": "/all-exam-order-institute",
  "db_slug_exam_orders_institute": "institute_exam_order",


  "new_order": "/new-paid-exam-order",
  "order_details": "/paid-exam-order/",
  "all_orders": "/all-paid-exam-order",
  "db_slug_paid_exam_orders": "paid_exam_orders",

  "daily_result_details": "/batch-results/",
  "all_daily_results": "/all-batch-result",
  "db_slug_daily_results": "daily_results",

  "real_time_result_details": "/real-time-results/",
  "all_real_time_results": "/all-real-time-result",
  "db_slug_real_time_results": "real_time_results",


  "new_student": "/new-student",
  "student_details": "/students/",
  "all_students": "/all-students",
  "db_slug_user_data": "user_data",

  "new_batch": "/new-batch",
  "batch_details": "/batches/",
  "all_batch": "/all-batches",
  "db_slug_batch": "student_batch",


  "new_institute_daily_exam": "/new-insitute-batch-exam",
  "institute_daily_exam_details": "/institute-batch-exams/",
  "all_institute_daily_exam": "/all-institute-batch-exam",
  
  "new_batch_institute": "/new-institute-batch",
  "institute_batch_details": "/institute-batches/",
  "all_institute_batch": "/all-institute-batches",

  "new_institute_batch_order": "/new-institute-batch-order",
  "institute_batch_order_details": "/institute-batch-order/",
  "all_institute_batch_orders": "/all-institute-batch-order",
  "db_slug_institute_batch_orders": "institute_batchorder",

  "new_institute_exam_order": "/new-institute-exam-order",
  "institute_exam_order_details": "/institute-exam-order/",
  "all_institute_exam_orders": "/all-institute-exam-order",
  "db_slug_institute_exam_orders": "institute_exam_order",

  "institute_daily_result_details": "/institute-batch-results/",
  "all_institute_daily_results": "/all-institute-batch-result",

  "exam_payment_institute": "/exam-payment-institute",
  "batch_payment_institute": "/batch-payment-institute",
 
  "exam_payment_insititute_verification": "/exam-payment-institute-verification",
  "batch_payment_institute_verification": "/batch-payment-institute-verification",

  "exam": "/all-exams-list",
  "demo_exam": "/all-demo-exam-list",
  "chapter_wise_exam": "/all-chapters-list",
  "daily_exam": "/all-batch-exam-list",
  "real_time_exam": "/all-real-time-exam-list",
  "exam_payment": "/exam-payment",
  "batch_payment": "/batch-payment",
  "batch_exam": "/batch-exam",
  "institute_list":'/insititute-list',
  "institute_details_user": "/institute-details/",

  "start_exam": "/start-exam",
  "start_demo_exam": "/start-demo-exam",
  "start_chapter_wise_exam": "/start-chapter-exam",
  "start_daily_exam": "/start-batch-exam",
  "start_real_time_exam": "/start-real-time-exam",
  
  "exam_result_list": "/my-exam-results",
  "daily_exam_result_list": "/my-batch-results",
  "real_time_exam_result_list": "/my-real-time-results",

  "exam_result": "/exam-result",
  "daily_exam_result": "/batch-exam-result",
  "real_time_exam_result": "/real-time-exam-result",
}