import axios from "axios";
import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi, NodePOSTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import ImageUpload from "../../../ImageUpload";
const studentRoles = ["subscriber", "student"]
export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.meta.institute_name)
  const [userID, setUserID] = React.useState(FetchData.meta.user_id)
  const [userName, setUserName] = React.useState(FetchData.meta.user_name)
  const [Password, setPassword] = React.useState("")
  const [userEmail, setUserEmail] = React.useState(FetchData.meta.user_email)
  const [PhoneNumber, setPhoneNumber] = React.useState(FetchData.meta.user_phone_number)
  const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
  const [InstituteAddress, setInstituteAddress] = React.useState(FetchData.meta.institute_address)
  const [InstituteCity, setInstituteCity] = React.useState(FetchData.meta.institute_city)

  const [ProfilePicture, setProfilePicture] = React.useState(FetchData.meta.institute_logo)
  const [InstituteEmail, setInstituteEmail] = React.useState(FetchData.meta.institute_email)
  const [InstitutePhoneNumber1, setInstitutePhoneNumber1] = React.useState(FetchData.meta.institute_phone_number_1)
  const [InstitutePhoneNumber2, setInstitutePhoneNumber2] = React.useState(FetchData.meta.institute_phone_number_2)
  const [InstituteGallery, setInstituteGallary] = React.useState(FetchData.meta.institute_gallary)
  const [InstituteShortDescription, setInstituteShortDescription] = React.useState(FetchData.meta.institute_short_description)
  const [InstituteDescription, setInstituteDescription] = React.useState(FetchData.meta.institute_description)



  const [EmailSubject, setEmailSubject] = React.useState("")
  const [EmailContent, setEmailContent] = React.useState("")

  const [subscriptionTime, setSubscriptionTime] = React.useState("")
  const [Datapassword, setDataPassword] = React.useState(FetchData.id == "" ? true : false)


  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const AfterUserCreate = (id) => {
    const data = {
      "meta": {
        "user_phone_number": PhoneNumber,
        "verification_status": VerificationStatus,
        "user_id": `${id}`,
        "user_name": userName,
        "user_email": userEmail,
        "institute_name": Title,
        "institute_address": InstituteAddress,
        "institute_city": InstituteCity,
        "institute_logo": ProfilePicture,
        "institute_email": InstituteEmail,
        "institute_phone_number_1": InstitutePhoneNumber1,
        "institute_phone_number_2": InstitutePhoneNumber2,
        "institute_gallary": InstituteGallery,
        "institute_short_description": InstituteShortDescription,
        "institute_description": InstituteDescription
      }
    }
    AdsPUTApi(data, slugs.db_slug_institute, ID)
      .then((res) => {
        setReload(false)
        setAlertShow(true)
        setAlertcomp(`${Title} edited Successfully`)
      }).catch(err => {
        console.log(err)
        console.warn(err.response.data)
        console.log("Response Status: hello11", err.response.status);
        console.log("Response Headers:", err.response.headers);
        setReload(false)
        setAlertShow(true)
        setAlertcomp(err.response.data.message)
      })
  }
  const EditInfo = () => {
    setReload(true)

    if (Datapassword) {
      const dataWithPassword = {
        "password": Password,
      }
      AdsPUTApi(dataWithPassword, `users`, `${userID}`)
        .then(res => {
          AfterUserCreate(userID)
        })
        .catch(e =>
          console.log(e)
        )
    }
    else {
      AfterUserCreate(userID)
    }
  }

  const SendEmail = () => {
    setReload(true)
    NodePOSTApi({
      email: userEmail,
      subject: EmailSubject,
      body: EmailContent
    }, "send_email")
      .then(res => {
        alert("EMail sent successfully")
      })
      .catch(e => {
        alert("email error")
      })
      .finally(() => {
        setReload(false)

      })


  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Institute Admin Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Institute Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Verification Status</td>
                <td className="col-6">
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"verified"} />
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"None"} />
                </td>
              </tr>

              <tr>
                <td className="col-3">User Phone Number</td>
                <td className="col-6">
                  <input className="input-common"
                    value={PhoneNumber} placeholder="Phone Number"
                    onChange={(event) => { setPhoneNumber(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">User Name</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userName} placeholder="User Name"
                      onChange={(event) => { setUserName(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userName}</div>
                  }
                </td>
              </tr>
              <tr>
                <td className="col-3">User Email</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userEmail} placeholder="User Email"
                      onChange={(event) => { setUserEmail(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userEmail}</div>
                  }
                </td>
              </tr>
              <tr>
                <td className="col-3">Password</td>
                <td className="col-6">
                  {Datapassword ?
                    <input className="input-common"
                      value={Password} placeholder="Password"
                      onChange={(event) => { setPassword(event.target.value) }}
                    />
                    :
                    <div>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setDataPassword(true)
                          setPassword("")
                        }}
                      >
                        <div className={"passive-option"}>
                          Change Password
                        </div>
                      </button>
                    </div>
                  }
                </td>
              </tr>

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Institute
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Institute Other Information</center></h4>
          <table>
            <tr>
              <td className="col-3">Institute Logo</td>
              <td className="col-6">
                <ImageUpload
                  ImageUrl={ProfilePicture}
                  setImageUrl={setProfilePicture}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Institute Address</td>
              <td className="col-6">
                <input className="input-common"
                  value={InstituteAddress} placeholder="Institute Address"
                  onChange={(event) => { setInstituteAddress(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Institute City</td>
              <td className="col-6">
                <input className="input-common"
                  value={InstituteCity} placeholder="City"
                  onChange={(event) => { setInstituteCity(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Institute Email</td>
              <td className="col-6">
                <input className="input-common"
                  value={InstituteEmail} placeholder="Institute Email"
                  onChange={(event) => { setInstituteEmail(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Institute Phone Number 1</td>
              <td className="col-6">
                <input className="input-common"
                  value={InstitutePhoneNumber1} placeholder="Institute Phone Number1"
                  onChange={(event) => { setInstitutePhoneNumber1(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Institute Phone Number 2</td>
              <td className="col-6">
                <input className="input-common"
                  value={InstitutePhoneNumber2} placeholder="Institute Phone Number 2"
                  onChange={(event) => { setInstitutePhoneNumber2(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Institute Short Description</td>
              <td className="col-6">
                <input className="input-common"
                  value={InstituteShortDescription} placeholder="Institute Short Description"
                  onChange={(event) => { setInstituteShortDescription(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Institute Description</td>
              <td className="col-6">
                <textarea rows={10} className="input-common"
                  value={InstituteDescription} placeholder="Institute Short Description"
                  onChange={(event) => { setInstituteDescription(event.target.value) }}
                />
              </td>
            </tr>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Institute
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Email Institute</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Institute Email</td>
                <td className="col-6">
                  <div className="input-common" >{userEmail}</div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Subject</td>
                <td className="col-6">
                  <input className="input-common"
                    value={EmailSubject} placeholder="Email Subject"
                    onChange={(event) => { setEmailSubject(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Email Content</td>
                <td className="col-6">
                  <textarea rows={7} className="input-common"
                    value={EmailContent} placeholder="Email Content"
                    onChange={(event) => { setEmailContent(event.target.value) }}
                  />
                </td>
              </tr>
              <tr onClick={() => {
                SendEmail()
              }}>
                <td colSpan={2}>
                  Send Email
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}