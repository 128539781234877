import React, { useEffect } from 'react';
import { SortOrder } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';
import store from '../../../redux/store';
import ExamBlock from './ExamBlock';


const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}
export default function AllPaidExamList() {
  const [AllExamList1, setAllExamList1] = React.useState({
    exam_data: []
  });
  const [AllExamList2, setAllExamList2] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [pages, setPages] = React.useState(1);
  const [AllExam, setAllExam] = React.useState([]);
  const [NewExam, setNewExam] = React.useState([]);
  const [MyExam, setMyExam] = React.useState([]);
  const [UnverifiedExam, setUnVerifiedExam] = React.useState([]);
  const [CompletedExam, setCompletedExam] = React.useState([]);

  const dispatch = useDispatch()

  const fetchExam = (setAllExamList, perPage) => {
    setReload(true)
    const id = store.getState().LoginState.userID
    NodePOSTApi({
      userID: id
    }, "all-exam-listing")
      .then((res) => {
        setAllExamList(res.data)
        setAllExam(res.data.exam_data)
        setReload(false)
        setPagesLength(res.headers["x-wp-totalpages"])

        var arrCompleted = [];
        var arrMy = [];
        var arrAll = [];
        var arrUnVerified = [];
        var arrNew = [];
        if (res.data.order_data.length !== 0)
          for (let i = 0; i < res.data.exam_data.length; i++) {
            var item = res.data.exam_data[i];
            const orderDataFilter = res.data.order_data.filter(a => a.meta.exam_id_order == item.id)
            if (orderDataFilter.length !== 0) {
              if (res.data.result_data.filter(b => b.meta.exam_id_results == item.id).length !== 0) {
                arrCompleted.push(item)
                setCompletedExam(arrCompleted)
              }
              else {
                if (orderDataFilter[0].meta.verification_status == "Verified" && orderDataFilter[0].meta.verification_id !== "") {
                  arrMy.push(item)
                  setMyExam(arrMy)
                }
                else {
                  arrUnVerified.push(item)
                  arrNew.push(item)
                  setNewExam(arrNew)
                  setUnVerifiedExam(arrUnVerified)
                }
              }
            }
            else {
              if (item.meta.exam_price == "0" || item.meta.exam_price == "") {
                if (res.data.result_data.filter(b => b.meta.exam_id_results == item.id).length !== 0) {
                  arrCompleted.push(item)
                  setCompletedExam(arrCompleted)
                }
                else {
                  arrMy.push(item)
                  setMyExam(arrMy)
                }
              }
              else {
                arrNew.push(item)
                setNewExam(arrNew)
              }
            }
          }
        else {
          setMyExam([])
          setCompletedExam([])
          setAllExam(res.data.exam_data)
        }
        console.log(res.data.length)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        if (err.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
  }


  const location = useLocation()
  useEffect(() => {
    fetchExam(setAllExamList1, {})
    console.log(location.pathname.slice(11))
  }, [])



  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div className="row" style={{ margin: "10px" }}>
            <h2>All Exams </h2>
            <div style={{ margin: "10px", cursor: "pointer" }}>
              <span onClick={() => { setAllExam(AllExamList1.exam_data) }}
              > All Exam ({AllExamList1.exam_data.length}) | </span>
              <span onClick={() => { setAllExam(MyExam) }}
              >My Exam ({MyExam.length}) | </span>
              <span onClick={() => { setAllExam(NewExam) }}
              >New Exam ({NewExam.length}) | </span>
              <span onClick={() => { setAllExam(CompletedExam) }}
              >Completed Exam ({CompletedExam.length}) | </span>
              <span onClick={() => { setAllExam(UnverifiedExam) }}
              >Unverified Exam ({UnverifiedExam.length}) | </span>
            </div>

            {AllExam.map((item, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-12 center" style={{ padding: "0 5px" }}>
                <ExamBlock
                  item={item} examSlug={slugs.start_exam} AllExamList1={AllExamList1}
                  fetchExam={fetchExam} setAllExamList1={setAllExamList1}
                />
              </div>
            ))}
          </div>

        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}