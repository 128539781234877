import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../ExamDetails';
import InstituteBatchList from './BatchesList';

export default function InstituteDetailsUSer(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [AllExamList1, setAllExamList] = React.useState({});
  const [reload, setReload] = React.useState(true)
  const [NotFoundData, setNotFound] = React.useState(false)
  const history = useHistory()

  const fetchExam = (slug) => {
    setReload(true)
    AdsGETApi({

    }, `institute_data/${slug}`)
      .then((res) => {
        setAllExamList(res.data)
        setReload(false)
      })
      .catch(() => {
        setReload(false)
        setNotFound(true)
      })
  }
  let myarray = []

  useEffect(() => {
    fetchExam(location.pathname.slice(slugs.institute_details_user.length))
    return history.listen((location) => {
      if (location.pathname.includes(slugs.institute_details_user)) {
        window.scrollTo(0, 0)
        fetchExam(location.pathname.slice(slugs.institute_details_user.length))
      }
    })
  }, [])

  return (
    <div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
      <div>
        {!reload &&
          <>
            {NotFoundData ?
              <NotFound /> :
              <>
                <div className="row">
                  <div className="col-sm-2 col-1"></div>
                  <div className="col-sm-8 col-10 form-view m-3">

                    <>
                      <table>
                        <tbody>
                          <tr>
                            <td>Institute Name</td>
                            <td>{AllExamList1.meta.institute_name}</td>
                          </tr>
                          <tr>
                            <td>Institute Address</td>
                            <td>{AllExamList1.meta.institute_address}</td>
                          </tr>
                          <tr>
                            <td>Institute City</td>
                            <td>{AllExamList1.meta.institute_city}</td>
                          </tr>
                          <tr>
                            <td>Institute Email</td>
                            <td>{AllExamList1.meta.institute_email}</td>
                          </tr>
                          <tr>
                            <td>Institute Phone Number1</td>
                            <td>{AllExamList1.meta.institute_phone_number_1}</td>
                          </tr>
                          <tr>
                            <td>Institute Phone Number2</td>
                            <td>{AllExamList1.meta.institute_phone_number_2}</td>
                          </tr>
                          <tr>
                            <td>About </td>
                            <td>{AllExamList1.meta.institute_short_description}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>

                  </div>

                </div>
                <div className="row">
                  <div className="col-sm-1 col-1"></div>
                  <div className="col-sm-10 col-10 form-view m-3">
                    <h3>Institute Batches</h3>
                    <InstituteBatchList
                      instituteID={AllExamList1.id}
                    />
                  </div>
                </div>

              </>
            }
          </>
        }
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}