import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { AdsGETApiWithToken, AdsPOSTApi, AdsPOSTApiWithoutAuth, AdsPOSTApiWithToken, UserMe, userSignIn } from '../Constant/api';
import { slugs } from '../Constant/slugs';
import { login } from '../redux/action';
// import ForgetPasswordComponent from './ForgetPassword';

export default function LoginScreen() {
  const [userName, setUserName] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [PhoneNumber, setPhoneNumer] = React.useState("")

  const [reload, setReload] = React.useState(false)
  const [passwordShow, setPasswordShow] = React.useState(false)
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [Register, setRegister] = React.useState(false)
  const [loginSuccess, setLoginSuccess] = React.useState(false)
  const [forgetPasswordPressed, setForgetPasswordPressed] = React.useState(false)

  const dispatch = useDispatch()
  const UserInfo = () => {
    setReload(true)
    UserMe(window.btoa(`${userName}:${password}`))
      .then((res) => {
        AdsGETApiWithToken(
          {
            _fields: "id,meta.batch_id,meta.user_profile_picture,meta.user_phone_number",
            "filter[meta_query][1][key]": "user_id",
            "filter[meta_query][1][value][0]": `${res.data.id}`,
            "filter[meta_query][1][compare]": "=",
          },
          slugs.db_slug_user_data, window.btoa(`${userName}:${password}`))
          .then((response) => {
            if (response.data.length == 1) {
              if (res.data.roles[0] == "institute") {
                AdsGETApiWithToken(
                  {
                    _fields: "id,meta.batch_id,meta.user_profile_picture,meta.user_phone_number",
                    "filter[meta_query][1][key]": "user_id",
                    "filter[meta_query][1][value][0]": `${res.data.id}`,
                    "filter[meta_query][1][compare]": "=",
                  },
                  slugs.db_slug_institute, window.btoa(`${userName}:${password}`))
                  .then(res5 => {
                    if (res5.data.length !== 0) {
                      localStorage.setItem('instituteID', res5.data[0].id);
                      dispatch(login(
                        `${res.data.id}`,
                        `${res.data.name}`,
                        `${window.btoa(`${userName}:${password}`)}`,
                        `${res.data.user_email}`,
                        `${res.data.roles[0]}`,
                        `${response.data[0].meta.user_profile_picture}`,
                        `${response.data[0].meta.user_phone_number}`,
                        `${response.data[0].meta.batch_id}`,
                      ))
                    }
                    else {
                      setAlertShow(true)
                      setAlertcomp("User Not Found")
                    }
                  })
                  .catch((err) => {
                    console.warn(err);
                    console.warn(err.response.data);
                    console.log("Response Status:", err.response.status);
                    console.log("Response Headers:", err.response.headers);
                  }).finally(() =>
                    setReload(false)
                  )
              }
              else
                dispatch(login(
                  `${res.data.id}`,
                  `${res.data.name}`,
                  `${window.btoa(`${userName}:${password}`)}`,
                  `${res.data.user_email}`,
                  `${res.data.roles[0]}`,
                  `${response.data[0].meta.user_profile_picture}`,
                  `${response.data[0].meta.user_phone_number}`,
                  `${response.data[0].meta.batch_id}`,
                ))
            }
            else {
              setAlertShow(true)
              setAlertcomp("User Not Found")
            }
          })
        setLoginSuccess(true)
      }).catch((err) => {
        console.warn(err);
        console.warn(err.response.data);
        console.log("Response Status:", err.response.status);
        console.log("Response Headers:", err.response.headers);
      }).finally(() =>
        setReload(false)
      )
  }
  const RegisterHandle = () => {
    setReload(true)
    const dataWithPassword = {
      "username": userName,
      "email": email,
      "password": password,
      "roles": "subscriber"
    }
    AdsPOSTApiWithoutAuth(dataWithPassword, "users")
      .then(res => {
        const data = {
          "title": userName,
          "status": "publish",
          "author": res.data.id,
          "meta": {
            "user_role": "subscriber",
            "user_profile_picture": "",
            "user_subscription_start": "",
            "user_subscription_end": "",
            "user_subscription_time": "",
            "user_phone_number": PhoneNumber,
            "verification_status": "None",
            "user_id": `${res.data.id}`,
            "user_name": userName,
            "user_email": email,
            "user_verification_history": [],
            "batch_id": ""
          }
        }
        AdsPOSTApiWithToken(data, slugs.db_slug_user_data, window.btoa(`${userName}:${password}`))
          .then((res) => {
            setReload(false)
            UserInfo()
          }).catch(err => {
            setReload(false)
            console.log(err)
            setAlertShow(true)
            setAlertcomp(err.response.data.message)
          })
      })
      .catch(err => {
        console.log(err)
        setAlertShow(true)
        setAlertcomp(err.response.data.message)
        setReload(false)
      }
      )
  }
  const AlertComponent = () => {
    return (
      <div className="modal"
        onClick={() => {
          setAlertShow(false)
        }}
      >
        <div>
          <div className="row " style={{ minHeight: "10vh", marginTop: "10vh" }}>
            <div className="col-lg-4 col-1"></div>
            <div className="col-lg-4 col-10 pb-5  bg-white  shadow">
              <div className="p-5">
                <div dangerouslySetInnerHTML={{ __html: Alertcomp }}></div>
              </div>
              <div style={{ display: 'flex', cursor: 'pointer' }} className="center">
                <div className="sign-in-button-1 col-8">
                  OK
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ backgroundColor: "#fff", minHeight: "100vh", width: "100vw", overflow: 'hidden' }}>
      <div className="row">
        {/* {loginSuccess &&
          <Navigate to="/" />
        } */}
        <div className="col-lg-2 col-1"></div>
        <div className="col-lg-8 col-10 shadow signin-container" style={{ backgroundColor: "#fcebeb" }}>
          <div className="py-5">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={"https://skysewa.com/wp-content/uploads/2023/01/skytech-logo-wo-bg-1536x522.png"}
                // src={"https://ubtvirtualtest.com/wp-content/uploads/2022/05/ubt-logo.png"}
                height={"100px"}
              />
            </div>
            {!Register ?
              <>
                <div className={"font-size-24"} style={{ textAlign: 'center', fontWeight: "bold" }}>
                  Log in to SKY SEWA

                </div>
                <div className="center">
                  Enter your username and password to login.
                </div>
              </>
              :
              <>
                <div className={"font-size-24"} style={{ textAlign: 'center', fontWeight: "bold" }}>
                  Register to SKY Sewa
                </div>
              </>
            }
            <div className="row">
              <div className="col-lg-3 col-1" />
              <div className="col-lg-6 col-10 px-5">

                <div className="label-form">Username.</div>
                <input
                  className="col-12  input-style"
                  type="text"
                  value={userName}
                  onChange={(event) => { setUserName(event.target.value) }}
                  placeholder="Enter UserName"
                  required
                /><br />
                {/* <div className="label-form">Phone Number.</div>
                <input
                  className="col-12  input-style"
                  type="text"
                  value={PhoneNumber}
                  onChange={(event) => { setPhoneNumer(event.target.value) }}
                  placeholder="Enter Phone Number"
                  required
                /><br /> */}
                <div className="label-form">Password.</div>
                <input
                  className="col-12  input-style"
                  type={passwordShow ? "text" : "password"}
                  value={password}
                  onChange={(event) => { setPassword(event.target.value) }}
                  placeholder="Enter Password"
                  required
                /><br />
                {Register &&
                  <>
                    <div className="label-form">Confirm Password.</div>
                    <input
                      className="col-12  input-style"
                      type={passwordShow ? "text" : "password"}
                      value={confirmPassword}

                      onChange={(event) => { setConfirmPassword(event.target.value) }}
                      placeholder="Confirm Password"
                      required
                    /><br />
                  </>
                }
                <div className="row">
                  <div className="row " style={{ cursor: 'pointer', width: "100%" }}
                    onClick={() => setPasswordShow(!passwordShow)}
                  >
                    <div className="showPassword" style={{ width: "10%", }}>
                      {!passwordShow ?
                        <i className="fa fa-square-o" />
                        :
                        <i className="fa fa-check-square-o" />
                      }
                    </div>
                    <div className="showPassword" style={{ width: "80%" }}>Show password</div>
                  </div>
                </div>
                {Register &&
                  <>
                    <div className="label-form">Email.</div>
                    <input
                      className="col-12  input-style"
                      type="text"
                      value={email}
                      onChange={(event) => { setEmail(event.target.value) }}
                      placeholder="Enter Email"
                      required
                    /><br />
                    <div className="label-form">Phone Number.</div>
                    <input
                      className="col-12  input-style"
                      type="text"
                      value={PhoneNumber}
                      onChange={(event) => { setPhoneNumer(event.target.value) }}
                      placeholder="Enter Phone Number"
                      required
                    /><br />
                  </>
                }
                <a href="tel:+9779801433555" className="Link">
                  <h6 >
                    <center>Contact No: +9779801433555 </center>
                  </h6>
                </a>
                <a href="tel:+9779857083555" className="Link">
                  <h6 >
                    <center>Contact No: +9779857083555 </center>
                  </h6>
                </a>
                {!Register ?
                  <div className="center">
                    <button onClick={() => {
                      UserInfo()
                    }} className="sign-in-button-1 col-8">
                      Sign In
                    </button>
                    <br />
                    <button className="sign-in-button-2 col-8"
                      onClick={() => {
                        setRegister(true)
                      }}
                    >
                      Register
                    </button>
                  </div>
                  :
                  <div className="center">
                    <button onClick={() => {
                      if (confirmPassword !== password) {
                        setAlertShow(true)
                        setAlertcomp("password and confirm password not same")
                      }
                      if (email !== "" && userName !== "" && password !== "")
                        RegisterHandle()
                      else {
                        setAlertShow(true)
                        setAlertcomp("fill all the fileds before register")
                      }
                    }} className="sign-in-button-1 col-8">
                      Register Now
                    </button>
                    <br />
                    <div style={{ cursor: "pointer" }}
                      onClick={() => {
                        setRegister(false)
                      }}
                    >
                      Already have a account. Sign in Now.
                    </div>
                  </div>
                }
                <a href="https://oldsite.skysewa.com/" className="Link">
                  <h6 >
                    <center>Old Website</center>
                  </h6>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {AlertShow &&
        <AlertComponent />
      }
    </div>
  )
}